$(document).on('click', '.w-accord-head', function () {
  var parent = $('.w-accord'),
    btn = $('.w-accord-head');
  var activeTab = $(this).closest(parent).attr('id');

  $(this)
    .toggleClass('active')
    .siblings('.w-accord-body')
    .slideToggle();

  $(this)
    .closest(parent)
    .toggleClass('active')
    .siblings(parent)
    .removeClass('active');

  if (!$(this).closest('.w-accord').hasClass('w-unbound')) {
    $(this).closest('.w-accord').siblings().find(btn).not($(this)).each(function (i) {
      $(this)
        .removeClass('active')
        .siblings('.w-accord-body')
        .slideUp();
    });
  }

  if ($(this).parent().hasClass('w-tab-section')) {
    $(this).closest(parent).addClass('active');
    $('.w-tab[data-tab="' + activeTab + '"]').addClass('current').siblings('.w-tab').removeClass('current');
  }

});

$(document).ready(function () {
  if ($('.w-accord.w-tab-section.active').length > 0) {
    $('.w-accord.w-tab-section.active').each(function () {
      if ($(this).find('.w-accord-head').hasClass('active')) {
        $(this).find('.w-accord-body').css('display', 'block');
      }
    });
  }
});
var currentScroll = 0;
var lastScrollTop = 0;
/**********common functions*****************/
function stopPropagation() {
  $('.stppg').on('click', function (event) {
    event.stopPropagation();
  });
}
function resolutionCheck() {
  if ($('.i').length > 0) {
    var res = parseInt($('.i').css('width'));
    return res;
  }
}
function open() {
  $(document).on('click touch', '*[data-open]', function () {
    var target = $(this).attr('data-open');
    $('#' + target).addClass('active');
    if ($('#' + target).hasClass('mdl-wrap')) {
      if (!$(this).hasClass('w-mdl-close')) {
        if ($('.touchevents').length) {
          overlayStart();
        }
      }
    }
  });
  // $(document).on('click', '*[data-open*="mdl"]', function(){
  // 	if(!$(this).hasClass('w-mdl-close')){
  // 		if($('.touchevents').length){
  // 			overlayStart();
  // 		}
  // 	}
  // });
}
function close() {
  $(document).on('click touch', '*[data-close]', function (event) {
    event.stopPropagation();
    var target = $(this).attr('data-close');
    $('#' + target).removeClass('active');
  });
}
function closeMDL() {
  $(document).on('click touch', '.w-mdl-close', function () {
    if ($('.mdl-wrap.active').length > 1) {
      if ($(this).hasClass('mdl-wrap')) {
        $(this).removeClass('active');
      }
      else {
        $(this).closest('.mdl-wrap').removeClass('active');
      }
    }
    else {
      if ($(this).hasClass('mdl-wrap')) {
        $(this).removeClass('active');
      }
      else {
        $(this).closest('.mdl-wrap').removeClass('active');
      }
      if ($('.touchevents').length) {
        overlayEnd();
      }
    }
  })
  $(document).on('click touch', '.mdl', function (event) {
    event.stopPropagation();
  });
}
function drop() {
  $(document).on('click touch', '*[data-drop]', function () {
    var target = $(this).attr('data-drop');
    $('.w-dropdown:not(#' + target + ')').removeClass('active');
    $('*[data-drop]').not(this).removeClass('active');
    if (!$('#' + target).hasClass('active')) {
      $('#' + target).addClass('active');
      $(this).addClass('active');
    }
    else {
      $('#' + target).removeClass('active');
      $(this).removeClass('active');
    }
  });
}
function closeDropdown() {
  $(document).on('click touch', function (event) {
    if ($(event.target).closest('*[data-drop]').length) {
      return;
    }
    $('.w-dropdown')
      .removeClass('active right left under above')
    // .css({
    // 	'max-width':'none'
    // });
    $('*[data-drop]').removeClass('active');
    // event.stopPropagation();
  });
}
$(document).on('click touch', '.w-dropdown', function (event) {
  if ($('.w-dropdown').hasClass('active')) {
    event.stopPropagation();
  }
});
function tooltipToggle() {
  $(document).on('mouseenter mouseout', '*[data-hover]', function () {
    var target = $(this).attr('data-hover'),
      item = $('#' + target);
    if (!item.hasClass('active')) {
      item.addClass('active');
    }
    else {
      item.removeClass('active');
    }
  });
}

$(document).on('mouseenter', '*[data-hover]', function (event) {
  event.stopPropagation();
  var element = $(this),
    target = $(this).attr('data-hover');
  definePoistion(element, target, 'tooltip');
});
$(document).on('click touch', '*[data-popover-position]', function (event) {
  event.stopPropagation();
  var element = $(this),
    target = $(this).attr('data-drop');
  definePoistion(element, target, 'popover');
});
$(document).on('mouseover', '.dropdown__item-wrap', function () {
  var element = $(this);
  var target = $(this).children('.dropdown__submenu');
  if (target.length) {
    target.addClass('active');
    target.siblings('.dropdown__item').addClass('active');
    definePoistion(element, target, 'submenu');
  }
});

$(document).on('mouseleave', '.dropdown__item-wrap', function () {
  var target = $(this).children('.dropdown__submenu');
  if (target.length) {
    if (target.hasClass('active')) {
      target.removeClass('active');
      target.siblings('.dropdown__item').removeClass('active');
    }
  }
});

function toggle() {
  $(document).on('click', '*[data-toggle]', function (event) {
    event.stopPropagation();
    var toggleIndicator = true;
    if (toggleIndicator == true) {
      toggleIndicator = false;
      var target = $(this).attr('data-toggle');
      if (!$('#' + target).hasClass('active')) {
        $('#' + target).addClass('active');
        $(this).addClass('active');
        setTimeout(function () {
          toggleIndicator = true;
        }, 250);
      }
      else {
        $('#' + target).removeClass('active');
        $(this).removeClass('active');
        setTimeout(function () {
          toggleIndicator = true;
        }, 250);
      }
    }
  });
}
function overlayStart() {
  var wS = window.pageYOffset;
  $('body').addClass('overlay-open');
  $('body').css('top', -wS + 'px');
}
function overlayEnd() {
  var wS = Math.abs(parseInt($('body').css('top')));
  $('body').css('top', 'auto');
  $('body').removeClass('overlay-open');
  $(window).scrollTop(wS);
}
function remove() {
  $(document).on('click', '*[data-remove]', function () {
    var target = $(this).attr('data-remove');
    $('#' + target).remove();
  });
}
function toggleTabsActiveClass() {
  $('.w-tab:not(.disabled)').on('click', function () {
    if ($(this).hasClass('current')) {
      return;
    }
    else {
      $(this).closest('.w-tab-parent').find('.w-tab').removeClass('current');
      $(this).addClass('current');
    }
  });
}
function toggleSwitchStatus() {
  $('.w-switch:not(.disabled)').on('click', function () {
    var target = $(this).parents('.switch__status');
    if (target.hasClass('current')) {
      return;
    }
    else {
      target.closest('.w-switch-parent').find('.switch__status').removeClass('current');
      target.addClass('current');
    }
  });
}
function toggleDisplayByTab() {
  $(document).on('click touch', '*[data-tab]', function () {
    var target = $(this).attr('data-tab');
    $(this).addClass('current').siblings('.w-tab').removeClass('current');
    $('#' + target).addClass('active').siblings('.w-tab-section').removeClass('active');
    if ($('#' + target).hasClass('w-accord')) {
      $('#' + target).find('.w-accord-head').addClass('active');
      $('#' + target).siblings('.w-accord').find('.w-accord-head').removeClass('active');
      $('#' + target).find('.w-accord-body').css('display', 'block');
      $('#' + target).siblings('.w-accord').find('.w-accord-body').css('display', 'none');
    }
  });
}
function triggerClick() {
  $(document).on('click', '*[data-trigger]', function () {
    var target = $(this).attr('data-trigger');
    $('#' + target).trigger('click');
  });
}
function scrollToTop() {
  $('.w-scroll-top').click(function () {
    $('html,body').animate({ scrollTop: 0 }, 500);
  });
}


/****DROPPED-ELEMENT-POSITION-DEFINE****/
$(document).on('click touch', '*[data-drop-position]', function (event) {
  var wS = $(window).scrollTop(),
    wH = $(window).height(),
    wW = $(window).width(),

    target = $(this).attr('data-drop'),
    item = $('#' + target),
    iHeight = $('#' + target).height(),
    iHeight = item.outerHeight(),
    iWidth = item.outerWidth(),

    eCoord = $(this).offset(),
    eTop = eCoord['top'],
    eLeft = eCoord['left'],
    ePosTop = eTop - wS,
    eWidth = $(this).outerWidth();


  /**********VERTICAL-POSITION-DEFINE*************/
  /************drop-down***********/
  if ((wH - ePosTop) > iHeight) {
    item
      .addClass('under')
      .removeClass('above')
  }
  /************drop-up***********/
  else if ((wH - ePosTop) <= iHeight && ePosTop > iHeight) {
    item
      .addClass('above')
      .removeClass('under')
  }
  /***********drop-neutral*****************/
  else if ((wH - ePosTop) <= iHeight && ePosTop < iHeight) {
    item
      .addClass('under')
      .removeClass('above')
  }
  /**************HORIZONTAL-POSITION**************/

  if (wW - eLeft >= iWidth) {
    item
      .addClass('right')
      .removeClass('left')
  }
  else {
    item
      .addClass('left')
      .removeClass('right')
  }

});
/****POPOVER-ELEMENT-POSITION-DEFINE****/
function definePoistion(element, target, type) {
  var wS = $(window).scrollTop(),
    wH = $(window).height(),
    wW = $(window).width(),

    eHeight = element.outerHeight(),
    eWidth = element.outerWidth(),
    eCoord = element.offset(),
    eTop = eCoord['top'],
    eLeft = eCoord['left'],
    ePosTop = eTop - wS,
    item = $(target).length ? target : $("#" + target),
    iHeight = item.outerHeight(),
    iWidth = item.outerWidth(),

    indent;

  if (element.parents('.txt').length) {
    indent = 7
  }
  else if (type == 'submenu') {
    indent = 2;
  }
  else {
    indent = 15;
  }
  /*************VERTICAL-POSITION-DEFINE*************/
  /************drop-up***********/
  if (type == 'submenu') {
    item.css({
      'top': 0 + 'px'
    });
  }
  else if (ePosTop >= (iHeight + 20)) {
    item
      .addClass('above')
      .removeClass('under')
      .css({
        'top': eTop - iHeight - indent + 'px'
      });

  }
  /************drop-down***********/
  else if (ePosTop < (iHeight + 20) && (wH - ePosTop) > (iHeight + 20)) {
    item
      .addClass('under')
      .removeClass('above')
      .css({
        'top': eTop + eHeight + indent + 'px'
      });
  }
  /*********drop-neutral*************/
  else if ((wH - ePosTop) <= (iHeight + 20) && ePosTop < (iHeight + 20)) {
    item
      .addClass('under')
      .removeClass('above')
      .css({
        'top': eTop + eHeight + indent + 'px'
      });
  }

  /**************HORIZONTAL-POSITION-DEFINE**************/
  if (type == 'tooltip') {
    item.css({
      'left': ((eLeft + eWidth / 2) - iWidth / 2) + 'px'
    })
  }
  else if (type == 'popover') {
    if (eLeft + iWidth <= wW) {
      item
        .removeClass('left')
        .addClass('right')
        .css({
          'left': eLeft + 'px'
        })
    }
    else if (eLeft + iWidth > wW && eLeft + eWidth >= iWidth) {
      item
        .removeClass('right')
        .addClass('left')
        .css({
          'left': (eLeft + eWidth - iWidth) + 'px'
        })
    }
    else if (eLeft + iWidth > wW && eLeft + eWidth < iWidth) {
      item
        .removeClass('right')
        .addClass('left')
        .css({
          'max-width': (eLeft + eWidth) + 'px',
          'left': (eLeft + eWidth - iWidth) + 'px'
        })
    }

  } else if (type == 'submenu') {
    item.css({
      'left': eWidth + indent + 'px'
    });
  }
}
$(document).ready(function () {
  // if($('.fileupload').length){
  // var progressGlobal = 0;
  // $('.fileupload__input').fileupload({
  // 	url: 'upload/upload.php',
  // 	singleFileUploads: false,
  // 	progressall: function(e, data) {
  // 		if(data.loaded > 0){
  // 			$('.fileupload').addClass('fileupload--in-progress');
  // 		}
  // 		var progress = parseInt(data.loaded / data.total * 100, 10);
  // 		progressGlobal = progress;
  // 		$('.fileupload__bar-inner').css('width', progress + '%');
  // 	},
  // 	done: function (e, data) {
  // 		if(progressGlobal == 100){
  // 			$('#fileupload')
  // 			.removeClass('fileupload--in-progress')
  // 			.addClass('fileupload--complete');
  // 		}
  // 		$.each(data.files, function (index, file) {
  // 			var newFile = $('<div class="fileupload__file"></div>');
  // 			$('.fileupload__files-wrap').append(newFile);
  // 			newFile.append(
  // 				'<span></span>',
  // 				'<button type="button" class="fileupload__btn-remove"></button>'
  // 			);
  // 			newFile.find('span').text(file.name);
  // 		});
  // 	}
  // });
  // }
});

$(document).on('click', '.fileupload:not(.single) .fileupload__file .fileupload__btn-remove, .fileupload:not(.single) .fileupload__progress .fileupload__btn-remove', function () {
  $(this).parent().remove();
});

$(document).on('click', '.fileupload.single .fileupload__file .fileupload__btn-remove', function () {
  $(this).closest('.fileupload').removeClass('fileupload--complete');
  $(this).closest('.fileupload__file').remove();
});
$(document).on('click', '.fileupload.single .fileupload__progress .fileupload__btn-remove', function () {
  $(this).closest('.fileupload').removeClass('fileupload--in-progress');
});

/*$(document).ready(function(){
	$('#data-table-1').DataTable({
		scrollY: '350px',
		scrollX: true,
		scrollCollapse: true,
		paging: false,
		searching: false,
		fixedColumns: {
			leftColumns: 1
		}
	});
	$('#editable-table').DataTable({
		scrollY: '350px',
		scrollX: true,
		scrollCollapse: true,
		paging: false,
		searching: false,
		fixedColumns: {
			leftColumns: 1
		}
	});
	$('#sortable-table').DataTable({
		paging: false,
		searching: false
	});


});*/
/**************FORM-ELEMENTS***********/
function formItemSplitHovered() {
  $('.btn-group .btn--drop-split').hover(
    function () { $(this).siblings('.btn').addClass('hovered') },
    function () { $(this).siblings('.btn').removeClass('hovered') }
  );
}
function formItemFocused() {
  $(document).on('focus', '.form-item', function () {
    $(this).parents('.form-item-wrap').addClass('focused');
  })
  $(document).on('focusout', '.form-item', function () {
    $(this).parents('.form-item-wrap').removeClass('focused');
  })
}
function removeErrorFromFormItem() {
  $(document).on('change input', '.form-item', function () {
    $(this).closest('.form-item-wrap').removeClass('error success warning');
  });
  $(document).on('change', 'input[type="checkbox"], input[type="radio"]', function () {
    $(this).parent().removeClass('error success warning');
  });
}
function showOnCheck() {
  $('*[data-check]').each(function () {
    var target = $(this).attr('data-check');
    if ($(this).prop('checked')) {
      $('#' + target).addClass('active');
    }
    else {
      $('#' + target).removeClass('active');
    }
  });
  $(document).on('click', '*[data-check]', function () {
    var target = $(this).attr('data-check');
    if ($(this).prop('checked')) {
      $('#' + target).addClass('active');
    }
    else {
      $('#' + target).removeClass('active');
    }
  });
}
function showOnRadioCheck() {
  $(document).on('change', '*[data-radio]', function () {
    var target = $(this).attr('data-radio');
    if ($(this).is(':checked') && $(this).val() == '1') {
      $('#' + target).addClass('active');
    }
    else {
      $('#' + target).removeClass('active');
    }
  });
}
function formItemActiveDisable() {
  $('*[data-disable]').each(function () {
    var target = $(this).attr('data-disable');
    if ($(this).prop('checked')) {
      $('#' + target).prop('disabled', false);
      $('#' + target).parent('.form-item-wrap').removeClass('disabled');
    }
    else {
      $('#' + target).prop('disabled', true);
      $('#' + target).parent('.form-item-wrap').addClass('disabled');
    }
  });
  $(document).on('click', '*[data-disable]', function () {
    var target = $(this).attr('data-disable');
    if ($(this).prop('checked')) {
      $('#' + target).prop('disabled', false);
      $('#' + target).parent('.form-item-wrap').removeClass('disabled');
    }
    else {
      $('#' + target).prop('disabled', true);
      $('#' + target).parent('.form-item-wrap').addClass('disabled');
      $('#' + target).val('');
    }
  });
}
function formItemInputNumCheck() {
  if ($('input.w-num').length > 0) {
    $('input.w-num').bind("change keyup input click", function () {
      if (this.value.match(/[^0-9]/g)) {
        this.value = this.value.replace(/[^0-9]/g, '');
      }
    });
  }
}
function formItemInputNumDotCheck() {
  if ($('input.w-num-dot').length > 0) {
    $('input.w-num-dot').bind("change keyup input click", function () {
      if (this.value.match(/[^.\d]+/g)) {
        this.value = this.value.replace(/[^.\d]+/g, '');
      }
    });
  }
}
function formItemEmailCheck() {
  $(document).on("blur change keyup input", 'input[type="email"]', function () {
    if (this.value.match(/^([a-z0-9_\-]+\.)*[a-z0-9_\-]+@([a-z0-9][a-z0-9\-]*[a-z0-9]\.)+[a-z]{2,4}$/i)) {
      //если email валиден
    }
    else {
      //если email не валиден
    }
  });
}
function preventKeyboardTriggeringOnMobile() {
  if ($('.touchevents').length) {
    /* Do not trigger keyboard popup on iOS*/
    $(document).on('mousedown', 'input[readonly]', function (e) {
      e.preventDefault();
    });
  }
}
function formCellZindexDominate() {
  $(document).on('click', '.w-zindex-dominate .form-cell', function () {
    $('.w-zindex-dominate .form-cell').each(function () {
      $(this).css('z-index', '9');
    });
    $(this).css('z-index', '10');
  });
}

/*===============================
=            Select2            =
===============================*/

if ($('.select2').length > 0) {
  $('.select2:not(.filter):not(.multiple)').each(function () {
    if (!$(this).parents('code').length) {
      $(this).select2({
        width: 'style',
        minimumResultsForSearch: -1,
        theme: 'uikit'
      });
    }
  });
}
if ($('.select2.filter').length > 0) {
  $('.select2.filter:not(.multiple)').each(function () {
    if (!$(this).parents('code').length) {
      $(this).select2({
        width: 'style',
        minimumResultsForSearch: 5,
        theme: 'uikit'
      });
    }
  });
}

if ($('.select2').length > 0) {
  $('select.select2').on('select2:open', function () {
    var wW = $(window).width(),

      item = $(this).data('select2').$dropdown.find('.select2-dropdown'),
      iWidth = item.outerWidth(),

      eCoord = $(this).offset(),
      eLeft = eCoord['left'],
      eWidth = $(this).parent('.form-item-wrap').outerWidth();

    $(this).data('select2').$dropdown.css('width', eWidth + 'px');

    /**************HORIZONTAL-POSITION**************/
    if (wW - eLeft >= iWidth) {
      item
        .addClass('right')
        .removeClass('left')
    }
    else {
      item
        .addClass('left')
        .removeClass('right')
    }
  });
}

// if ( $('.form-item-wrap--header-select').length ) {
// 	$('.form-item-wrap--header-select').each(function() {
// 		$(this).find('select.select2').data('select2').$dropdown.addClass("header-select-dropdown");
// 	});
// }

$('select.select2').on('select2:open', function () {
  $(this).parents('.form-item-wrap').addClass('focused');
});

$('select.select2').on('select2:close', function () {
  $(this).parents('.form-item-wrap').removeClass('focused');
});

/*----------  Select2 Multiselect  ----------*/

var selectCount = function (instance) {
  var selection = $(instance).siblings('.select2-container').find('.select2-selection__rendered');
  var count = $(instance).select2('data').length;
  var q = $(instance).find('option').length;
  if (count == 0) {
    selection.html("Select options");
  }
  else {
    selection.html(count + " out of " + q + " selected");
  }
};

$('.select2[multiple]').on('select2:select select2:unselect change', function () {
  selectCount(this);
});

// Select2 selection decorator
// Exclude inline search and other select2 multiselect options
$.fn.select2.amd.define('select2/customMultipleSelection', [
  'select2/utils',
  'select2/selection/multiple',
  'select2/selection/eventRelay',
], function (Utils, MultipleSelection, EventRelay) {

  function CustomMultipleSelection() { }

  CustomMultipleSelection.prototype.render = function (decorated) {
    var $rendered = decorated.call(this);
    var self = this;

    var $arrow = $(
      '<span class="select2-selection__arrow" role="presentation">' +
      '<b role="presentation"></b>' +
      '</span>'
    );
    $rendered.append($arrow);

    return $rendered;
  }

  return Utils.Decorate(
    Utils.Decorate(
      MultipleSelection,
      EventRelay
    ),
    CustomMultipleSelection
  );

});

// Select2 dropdown decorator
// Add search in dropdown and check/uncheck all buttons
$.fn.select2.amd.define('select2/s2multipleDropdown', [
  'select2/utils',
  'select2/dropdown',
  'select2/dropdown/search',
  'select2/dropdown/attachBody',
  'select2/dropdown/minimumResultsForSearch',
], function (Utils, Dropdown, DropdownSearch, AttachBody, MinimumResultsForSearch) {

  function S2multipleDropdown() { }

  S2multipleDropdown.prototype.render = function (decorated) {
    var $rendered = decorated.call(this);
    var self = this;
    var $selectAll = $(
      '<button class="select2-all" type="button">Check All</button>'
    );
    var $clearAll = $(
      '<button class="select2-all" type="button">Uncheck All</button>'
    );

    var $results = $rendered.find('.select2-results')

    $results.prepend($selectAll, $clearAll);

    $selectAll.on('click', function () {
      var $results = $rendered.find('.select2-results__option[aria-selected=false]');

      // Get all results that aren't selected
      $results.each(function () {
        var $result = $(this);

        // Get the data object for it
        var data = $result.data('data');

        // Trigger the select event
        self.trigger('select', {
          data: data
        });
      });

      // self.trigger('close');
    });

    $clearAll.on('click', function () {
      var $results = $rendered.find('.select2-results__option[aria-selected=true]');

      // Get all results that aren't selected
      $results.each(function () {
        var $result = $(this);

        // Get the data object for it
        var data = $result.data('data');

        // Trigger the select event
        self.trigger('unselect', {
          data: data
        });
      });

      // self.trigger('close');

      selectCount(self.$element);
    });

    self.$dropdown.addClass("select2-dropdown--multiple");
    return $rendered;
  };
  var SearchableDropdown = Utils.Decorate(Dropdown, DropdownSearch);
  return Utils.Decorate(
    Utils.Decorate(
      Utils.Decorate(
        SearchableDropdown,
        MinimumResultsForSearch
      ),
      AttachBody
    ),
    S2multipleDropdown
  );
});

if ($('.select2.multiple').length > 0) {
  $('.select2.multiple:not(.filter)').each(function () {
    if (!$(this).parents('code').length) {
      $(this).select2({
        width: 'style',
        theme: 'uikit',
        minimumResultsForSearch: -1,
        selectionAdapter: $.fn.select2.amd.require('select2/customMultipleSelection'),
        dropdownAdapter: $.fn.select2.amd.require('select2/s2multipleDropdown')
      });
      selectCount(this);
    }
  });
}

if ($('.select2.multiple.filter').length > 0) {
  $('.select2.multiple.filter').each(function () {
    if (!$(this).parents('code').length) {
      $(this).select2({
        width: 'style',
        theme: 'uikit',
        minimumResultsForSearch: 5,
        selectionAdapter: $.fn.select2.amd.require('select2/customMultipleSelection'),
        dropdownAdapter: $.fn.select2.amd.require('select2/s2multipleDropdown')
      });
      selectCount(this);
    }
  });
}
/*=====  End of Select2  ======*/
// ;(function(){
// 		var entityMap = {
// 			"&": "&amp;",
// 			"<": "&lt;",
// 			">": "&gt;",
// 			'"': '&quot;',
// 			"'": '&#39;',
// 			"/": '&#x2F;'
// 		};
// 		function escapeHtml(string) {
// 			return String(string).replace(/[&<>"'\/]/g, function (s) {
// 				return entityMap[s];
// 			});
// 		}

// 		window.onload = function init()
// 		{
// 			var codeblock = document.querySelectorAll("pre code");

// 			if(codeblock.length) 
// 			{
// 				for(var i=0, len=codeblock.length; i<len; i++)
// 				{
// 					var dom = codeblock[i];
// 					var html = dom.innerHTML;
// 					html = escapeHtml(html);
// 					dom.innerHTML = html;
// 				}
// 			}
// 		}
// }());


function mainMenuItemToggle() {
  $(document).on('click', '.main-menu__item--arrow', function () {
    $(this).parent().siblings('.main-menu__item-wrap').each(function () {
      $(this).find('.main-menu__item--arrow').removeClass('active');
    });
    $(this).addClass('active');
  })
}
function mainMenuSizeBtnDisplay() {
  var nav = $('.main-nav');
  var header = $('.main-header');
  var content = $('.main-container');

  var btn = $('.main-logo__menu-size-btn');
  var btnMin = $('.main-logo__menu-size-btn.min');
  var btnMax = $('.main-logo__menu-size-btn.max');

  if (nav.hasClass('max')) {
    btnMin.addClass('active');
  }
  else if (nav.hasClass('med')) {
    btn.addClass('active');
  }
  else if (nav.hasClass('min')) {
    btnMax.addClass('active');
  }

  $(document).on('click', '.main-logo__menu-size-btn', function () {
    if (nav.hasClass('max')) {
      nav.removeClass('max').addClass('med');
      header.removeClass('max').addClass('med');
      content.removeClass('max').addClass('med');
      btnMax.addClass('active');
    }
    else if (nav.hasClass('med') && $(this).hasClass('min')) {
      nav.removeClass('med').addClass('min');
      header.removeClass('med').addClass('min');
      content.removeClass('med').addClass('min');
      btnMin.removeClass('active');
    }
    else if (nav.hasClass('med') && $(this).hasClass('max')) {
      nav.removeClass('med').addClass('max');
      header.removeClass('med').addClass('max');
      content.removeClass('med').addClass('max');
      btnMax.removeClass('active');
    }
    else if (nav.hasClass('min')) {
      nav.removeClass('min').addClass('med');
      header.removeClass('min').addClass('med');
      content.removeClass('min').addClass('med');
      btnMin.addClass('active');
    }
  });
}
function mainMenuLvl2ScrollTop() {
  $(document).on('click', '.main-menu__item--arrow', function () {
    var top = $(this).scrollTop();
    setTimeout(function () {
      $('.main-menu-wrap').animate({ scrollTop: 0 }, 150);
    }, 300);
  });
}
$(document).ready(function () {
  stopPropagation();
  resolutionCheck();
  open();
  close();
  closeMDL();
  drop();
  tooltipToggle();
  closeDropdown();
  scrollToTop();
  triggerClick();
  toggleTabsActiveClass();
  toggleDisplayByTab();
  toggle();
  objectFitImages();
  toggleSwitchStatus();

  formItemSplitHovered();
  formItemFocused();
  showOnCheck();
  showOnRadioCheck();
  formItemActiveDisable();
  formItemInputNumCheck();
  formItemInputNumDotCheck();
  formItemEmailCheck();
  preventKeyboardTriggeringOnMobile();
  removeErrorFromFormItem();
  formCellZindexDominate();

  mainMenuItemToggle();
  mainMenuSizeBtnDisplay();
  mainMenuLvl2ScrollTop();

  svg4everybody();
  timePicker();
  hljs.initHighlightingOnLoad();

  tableCollapse();

  function dateDiffInDays(a, b) {
    var _MS_PER_DAY = 1000 * 60 * 60 * 24;
    var utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    var utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
  }

  if ($('.datepicker').length) {
    /*var isMacLike = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);
    var isIOS = /(iPhone|iPod|iPad)/i.test(navigator.platform);
    var datePickerClickElement;
    if(isMacLike || isIOS){
        datePickerClickElement = "button";
    }
    else {
        datePickerClickElement = "both";
    }*/
    $.datepicker._updateDatepicker_original = $.datepicker._updateDatepicker;
    $.datepicker._updateDatepicker = function (inst) {
      $.datepicker._updateDatepicker_original(inst);
      var afterShow = this._get(inst, 'afterShow');
      if (afterShow) {
        afterShow.apply((inst.input ? inst.input[0] : null));
      }
    };
    $('.datepicker:not(.week):not(.range):not(.multiple)').datepicker({
      changeMonth: true,
      changeYear: true,
      // showOn: datePickerClickElement,
      showOn: "button",
      afterShow: function () {
        $('.ui-datepicker-title select').select2({ width: 'style', minimumResultsForSearch: -1, theme: 'uikit' });
      },
      beforeShow: function () {
        $(this).addClass('visible');
      },
      minDate: new Date(),
      dateFormat: "mm/dd/yy",
      defaultDate: 1,
      onSelect: function () {
        $(this).data('datepicker').inline = true;
      },
      onClose: function () {
        $(this).data('datepicker').inline = false;
        $(this).removeClass('visible');
      },
      monthNamesShort: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
      dayNamesMin: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
    }).datepicker('setDate', new Date());

    var customPeriod = -($('.datepicker.custom-period').data('period'));
    $('.datepicker.custom-period').datepicker('option', 'minDate', customPeriod);

    $('.datepicker.week').datepicker({
      changeMonth: true,
      changeYear: true,
      // showOn: datePickerClickElement,
      showOn: "button",
      beforeShow: function (el, inst) {
        $(this).addClass('visible');

        var d = $(this).val().split(',')[0];
        $(this).datepicker('setDate', d);
      },
      afterShow: function () {
        $('.ui-datepicker-title select').select2({ width: 'style', minimumResultsForSearch: -1, theme: 'uikit' });
        $('.ui-datepicker-current-day').parent().addClass('datepicker-current-week');
        $('.ui-datepicker-calendar').addClass('week');
        var prevOther = $('.ui-datepicker-calendar.week tr:last-child').find('.ui-datepicker-other-month')[0];
        var nextOther = $('.ui-datepicker-calendar.week tr:first-child').find('.ui-datepicker-other-month').last();
        prevOther = $(prevOther).prev();
        nextOther = $(nextOther).next();
        $(prevOther).addClass('datepicker-prev-other');
        $(nextOther).addClass('datepicker-next-other');
      },
      minDate: new Date(),
      dateFormat: "mm/dd/yy",
      monthNamesShort: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
      dayNamesMin: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      onSelect: function (dateText, inst) {
        $(this).data('datepicker').inline = true;
        var startD = $(this).datepicker('getDate');
        startD.setDate(startD.getDate() - (startD.getDay()));
        var endD = new Date(startD.getTime());
        endD.setDate(endD.getDate() + 6);
        // selecting today + next # days
        // var startD = $(this).datepicker('getDate');
        // var endD = new Date(startD.getTime());
        // endD.setDate(endD.getDate() + 6);
        // $(inst.dpDiv).find('.ui-datepicker-current-day').parent().addClass('datepicker-current-week');

        $(this).siblings('span.form-item').html($.datepicker.formatDate('M d', startD) + ' - ' + $.datepicker.formatDate('M d', endD));

        var selectedDates = [];
        for (var i = 0; i < 7; i++) {
          var d = new Date(startD.getTime());
          d.setDate(d.getDate() + i);
          selectedDates.push($.datepicker.formatDate('mm/dd/yy', d));
        }
        selectedDates = selectedDates.join(', ');
        $(this).val(selectedDates);
      },
      onChangeMonthYear: function (year, month, inst) {
        var d = inst.dpDiv;
        var m = new Date();
        m = m.getMonth() + 1;
        cM = inst.currentMonth + 1;
        s = $(d).find('tr:last-child .ui-datepicker-current-day').length;
        setTimeout(function () {
          d = inst.dpDiv;
          if (month == cM + 1 && s) {
            $(d).find('tr:first-child').addClass('datepicker-current-week');
          }
        }, 100);
      },
      onClose: function () {
        $(this).data('datepicker').inline = false;
        $(this).removeClass('visible');
      }
    }).datepicker('setDate', new Date());

    $(".datepicker.range").datepicker({
      changeMonth: true,
      changeYear: true,
      // showOn: datePickerClickElement,
      showOn: "button",
      minDate: new Date(),
      dateFormat: "mm/dd/yy",
      defaultDate: 1,
      monthNamesShort: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
      dayNamesMin: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      afterShow: function () {
        $('.ui-datepicker-calendar').addClass('range');
        $('.ui-datepicker-title select').select2({ width: 'style', minimumResultsForSearch: -1, theme: 'uikit' });
        $('#ui-datepicker-div').find('.dp-highlight').removeClass('dp-highlight--first, dp-highlight--last');
        $('#ui-datepicker-div').find('.dp-highlight').first().addClass('dp-highlight--first');
        $('#ui-datepicker-div').find('.dp-highlight').last().addClass('dp-highlight--last');
      },
      beforeShow: function () {
        $(this).addClass('visible');
        var d = $(this).val().split(',')[0];
        $(this).datepicker('setDate', d);
      },
      beforeShowDay: function (date) {
        var dates = $(this).siblings('span.form-item').html().split(" ");
        var date1 = $.datepicker.parseDate($.datepicker._defaults.dateFormat, dates[0]);
        var date2 = $.datepicker.parseDate($.datepicker._defaults.dateFormat, dates[dates.length - 1]);
        var isHightlight =
          date1 && ((date.getTime() == date1.getTime()) || (date2 && date >= date1 && date <= date2));
        return [true, isHightlight ? 'dp-highlight' : ""];
      },
      onSelect: function (dateText, inst) {
        $(this).data('datepicker').inline = true;
        var dates = $(this).siblings('span.form-item').html().split(" ");
        var date1 = $.datepicker.parseDate($.datepicker._defaults.dateFormat, dates[0]);
        var date2 = $.datepicker.parseDate($.datepicker._defaults.dateFormat, dates[dates.length - 1]);
        var selectedDate = $.datepicker.parseDate($.datepicker._defaults.dateFormat, dateText);
        var startD = date1;
        var endD = date2;

        if (!date1 || date2) {
          startD = selectedDate;
          endD = "";
        }
        else if (selectedDate < date1) {
          endD = startD;
          startD = selectedDate;
        }
        else {
          endD = selectedDate;
        }

        if (endD) {
          var datesInRange = new Array(dateDiffInDays(startD, endD) + 1);
          for (var i = 0; i < datesInRange.length; i++) {
            var d = new Date(startD.getTime());
            d.setDate(d.getDate() + i);
            datesInRange[i] = $.datepicker.formatDate('mm/dd/yy', d);
          }
          datesInRange = datesInRange.join(', ');
          $(this).val(datesInRange);
        }

        startD = $.datepicker.formatDate('mm/dd/yy', startD);
        endD = $.datepicker.formatDate('mm/dd/yy', endD);
        $(this).siblings('span.form-item').html(startD + ' - ' + endD);
      },
      onClose: function () {
        $(this).data('datepicker').inline = false;
        $(this).removeClass('visible');
      }
    }).datepicker('setDate', new Date());

    $(".datepicker.range").each(function () {
      var date = $.datepicker.formatDate('mm/dd/yy', $(this).datepicker('getDate'));
      $(this).siblings('.form-item').html(date);
    });

    $(".datepicker.multiple").datepicker({
      changeMonth: true,
      changeYear: true,
      // showOn: datePickerClickElement,
      showOn: "button",
      minDate: new Date(),
      dateFormat: "mm/dd/yy",
      defaultDate: 1,
      monthNamesShort: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
      dayNamesMin: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      afterShow: function () {
        $('.ui-datepicker-calendar').addClass('multiple');
        $('.ui-datepicker-title select').select2({
          width: 'style',
          minimumResultsForSearch: -1,
          theme: 'uikit'
        });
      },
      beforeShow: function () {
        $(this).addClass('visible');
        var d = $(this).val().split(';')[0];
        $(this).datepicker('setDate', d);
      },
      beforeShowDay: function (date) {
        var dates = $(this).siblings("span.form-item").html().split(";");
        if (!dates[0]) { return [true, ""]; }
        dates = dates.map(function (x) {
          return $.datepicker.parseDate($.datepicker._defaults.dateFormat, x.trim())
            .getTime();
        });
        var isSelected = dates.indexOf(date.getTime()) == -1 ? false : true;
        return [true, isSelected ? "dp-selected" : ""];
      },
      onSelect: function (dateText, inst) {
        $(this).data('datepicker').inline = true;
        var selected = [];
        var dates = $(this).siblings("span.form-item").html().split(";");
        var selectedD = $.datepicker.parseDate($.datepicker._defaults.dateFormat, dateText)
          .getTime();

        if (dates[0]) {
          dates = dates.map(function (x) {
            return $.datepicker.parseDate($.datepicker._defaults.dateFormat, x.trim())
              .getTime();
          });
          for (var i = 0; i < dates.length; i++) {
            if (dates[i] != selectedD) {
              selected.push(dates[i]);
            }
          }
        }
        if (dates.indexOf(selectedD) == -1) {
          selected.push(selectedD);
        }

        selected = selected.map(function (x) {
          return $.datepicker.formatDate('mm/dd/yy', new Date(x));
        });
        var result = selected.join("; ");
        $(this).siblings("span.form-item").html(result);
        $(this).val(result);

      },
      onClose: function () {
        $(this).data('datepicker').inline = false;
        $(this).removeClass('visible');
      }
    }).datepicker("setDate", new Date());


    $(document).on("click touch", '.datepicker', function () {
      if ($(this).hasClass('visible')) {
        $(this).datepicker('hide');
      } else {
        $(this).datepicker('show');
      }
    });
  }

});

$(window).on('load', function () {

});

$(window).on('scroll', function () {
  var st = $(this).scrollTop();
  if (st > lastScrollTop) {
    // downscroll
  } else {
    // upscroll
  }
  lastScrollTop = st;

});

$(window).on('resize orientationchange', function () {
  var res = resolutionCheck();
});

$(document).ready(function () {
  var inputSearch = $('.w-search'),
    target = inputSearch.attr('data-search'),
    optionSet = $('#' + target);

});
$(document).ready(function () {
  var checkAll = '.ui-multiselect-filter + .ui-helper-reset .ui-multiselect-all';
  var uncheckAll = '.ui-multiselect-filter + .ui-helper-reset .ui-multiselect-none';
  $(document).on('mousedown mouseup touchstart touchend', checkAll + ',' + uncheckAll, function () {
    var input = $(this)
      .closest('.ui-multiselect-menu')
      .find('.ui-multiselect-checkboxes')
      .find('input');
    var parent = input.closest('li');
    if ($(this).hasClass('ui-multiselect-all')) {
      parent.each(function (i) {
        if ($(this).is(':visible')) {
          $(this).find('input').prop('checked', true);
        }
        else {
          $(this).find('input').prop('checked', false);
        }
      });
    }
    else {
      input.each(function () {
        $(this).prop('checked', false);
      });
    }

  });

  var multialtCheckAll = '.multiselect-alt__checkall';
  var multialtUncheckAll = '.multiselect-alt__uncheckall';
  $(document).on('click touch', multialtCheckAll + ',' + multialtUncheckAll, function () {
    var input = $(this)
      .closest('.multiselect-alt__menu')
      .find('.multiselect-alt__options')
      .find('input');
    if ($(this).hasClass('multiselect-alt__checkall')) {
      input.each(function () {
        $(this).prop('checked', true);
      });
    }
    else {
      input.each(function () {
        $(this).prop('checked', false);
      });
    }

  });
});
function navTabsOverlay() {
  var item = $('.tab-nav.current'),
    zA = item.css('z-index');
  item.nextAll('.tab-nav').each(function () {
    var i = $(this).index();
    $(this).css('z-index', zA - i);
  });
  item.prevAll('.tab-nav').each(function () {
    var i = $(this).index();
    $(this).css('z-index', 'auto');
  });
}
$(document).ready(function () {
  if ($('.tab-nav').length) {
    navTabsOverlay();
  }
});
$(document).on('click', '.tab-nav', function () {
  navTabsOverlay();
});

function tableCollapse() {

  if ($('.w-table-collapse').length) {

    var $rowL1 = $('.w-table-collapse').find('.level-1-row');

    $rowL1.prev('.level-2-row').addClass('level-2-row-last');

    $rowL1.each(function () {
      if ($(this).next('.level-2-row').length == 0) {
        $(this).removeClass('active').addClass('disabled');
      } else {
        $(this).removeClass('disabled');
      }
    });

    $('.w-table-collapse:not(.dataTable)').on('click touch', '.level-1-row:not(.disabled)', function () {
      if ($(this).hasClass('active')) {
        $(this).removeClass('active');
        $(this).nextUntil('.level-1-row').removeClass('active');
      } else {
        $(this).addClass('active');
        $(this).nextUntil('.level-1-row').addClass('active');
      }
    });

    $('.w-table-collapse.dataTable').on('click touch', '.level-1-row:not(.disabled)', function () {
      var $table = $(this).parents('table');
      var dTable = $table.DataTable();
      var row = dTable.row(this).node();
      var $rowL2 = $(row).nextUntil('.level-1-row');

      if ($(row).hasClass('active')) {
        $(row).removeClass('active');
        $rowL2.removeClass('active');
        dTable.draw();
      } else {
        $(row).addClass('active');
        $rowL2.addClass('active');
        dTable.draw();
      }
    });

  }

}
/********************TIMEPICKER*********************/
function timePicker() {
  if ($('.timepicker').length) {
    $('.form-item--timepicker').each(function () {
      var target = $(this).attr('data-time');
      var currentTime = $('#' + target).val();
      if ($(this).hasClass('hours')) {
        var hours = currentTime.split(':')[0];
        $(this).val(hours);
      }
      else if ($(this).hasClass('minutes')) {
        var rightPart = currentTime.split(':')[1];
        var minutes = rightPart.split(' ')[0];
        $(this).val(minutes);
      }
    });
    $('.timepicker__ampm.am').each(function () {
      var target = $(this).attr('data-time');
      var time = $('#' + target).val();
      var rightPart = time.split(':')[1];
      var period = rightPart.split(' ')[1];
      if (period == 'AM') {
        $(this).addClass('current').siblings('.timepicker__ampm.pm').removeClass('current');
      }
      else {
        $(this).removeClass('current').siblings('.timepicker__ampm.pm').addClass('current');
      }
    });
    $('.form-item--timepicker-mini').each(function () {
      var target = $(this).attr('data-time');
      var currentPeriod = $('#' + target).val();
      var startPeriod = currentPeriod.split('-')[0];
      var endPeriod = currentPeriod.split('-')[1];
      var hoursStart = startPeriod.split(':')[0];
      var rightPartStart = startPeriod.split(':')[1];
      var minutesStart = rightPartStart.split(' ')[0];
      var ampmStart = rightPartStart.split(' ')[1];
      var hoursEnd = endPeriod.split(':')[0];
      hoursEnd = hoursEnd.replace(/\s/g, '');
      var rightPartEnd = endPeriod.split(':')[1];
      var minutesEnd = rightPartEnd.split(' ')[0];
      var ampmEnd = rightPartEnd.split(' ')[1];
      if ($(this).hasClass('start')) {
        if ($(this).hasClass('hours')) {
          $(this).val(hoursStart);
        }
        else if ($(this).hasClass('minutes')) {
          $(this).val(minutesStart);
        }
        else if ($(this).hasClass('ampm')) {
          $(this).val(ampmStart);
        }
      }
      if ($(this).hasClass('end')) {
        if ($(this).hasClass('hours')) {
          $(this).val(hoursEnd);
        }
        else if ($(this).hasClass('minutes')) {
          $(this).val(minutesEnd);
        }
        else if ($(this).hasClass('ampm')) {
          $(this).val(ampmEnd);
        }
      }
    });
  }
}
// $(document).on('change input', '.form-item--timepicker', function(){
// 	var target = $(this).attr('data-time');
// 	var timeInput = $('#'+target);
// 	var time = $('#'+target).val();
// 	var numbs = time.split(' ')[0];
// 	var period = time.split(' ')[1];
// 	var hours = numbs.split(':')[0];
// 	var minutes = numbs.split(':')[1];
// 	console.log(time);
// 	if($(this).closest('.timepicker').hasClass('big')){
// 		if ($(this).hasClass('hours')){
// 			if($(this).val() > 12){
// 				$(this).val('12');
// 			}
// 			else if($(this).val() < 0){
// 				$(this).val('01');
// 			}
// 			hours = $(this).val();
// 		}
// 		else if ($(this).hasClass('minutes')){
// 			if($(this).val() > 59 || $(this).val() < 0){
// 				$(this).val('00');
// 			}
// 			minutes = $(this).val();
// 		}
// 		timeInput.val(hours+':'+minutes+' '+period);
// 	}
// 	else if($(this).closest('.timepicker').hasClass('small')){
// 		if ($(this).hasClass('hours')){
// 			hours = $(this).val();
// 		}
// 		else if ($(this).hasClass('minutes')){
// 			if($(this).val() > 59 || $(this).val() < 0){
// 				$(this).val('00');
// 			}
// 			minutes = $(this).val();
// 		}
// 		timeInput.val(hours+':'+minutes);
// 	}
// });
$(document).on('click', '.timepicker__arrow, .timepicker-mini__arrow', function () {
  var target = $(this).attr('data-time');
  var currentTime = parseInt($('#' + target).val());
  var finalTimeTarget = $('#' + target).attr('data-time');
  var finalTimeInput = $('#' + finalTimeTarget);
  var isPeriod = false;
  var hoursStart, minutesStart, ampmStart, hoursEnd, minutesEnd, ampmEnd;
  if (($('#' + target).hasClass('end')) || ($('#' + target).hasClass('start'))) {
    isPeriod = true;
  }
  if (!isPeriod) {
    hoursStart = $(this).parents('.timepicker').find('.hours').val();
    minutesStart = $(this).parents('.timepicker').find('.minutes').val();
    ampmStart = $(this).parents('.timepicker').find('.form-item.ampm').val();
  }
  if (isPeriod) {
    hoursStart = $(this).parents('.timepicker').find('.hours.start').val();
    minutesStart = $(this).parents('.timepicker').find('.minutes.start').val();
    ampmStart = $(this).parents('.timepicker').find('.form-item.ampm.start').val();
    hoursEnd = $(this).parents('.timepicker').find('.hours.end').val();
    minutesEnd = $(this).parents('.timepicker').find('.minutes.end').val();
    ampmEnd = $(this).parents('.timepicker').find('.form-item.ampm.end').val();
  }
  var period = $(this).closest('.timepicker').find('.timepicker__ampm.current').text();
  var targetPeriod = $(this).attr('data-period');
  var targetPeriodVal = $('#' + targetPeriod).find('.timepicker__ampm.current');
  if ($(this).hasClass('hours')) {
    var min = 1;
    if ($(this).closest('.timepicker').hasClass('big')) {
      var max = 12;
    }
    else {
      var max = 23;
    }
    if ($(this).hasClass('plus')) {
      if ($('#' + target).val() == max && $(this).closest('.timepicker').hasClass('big')) {
        $('#' + target).val('01');
      }
      else if ($('#' + target).val() == max && $(this).closest('.timepicker').hasClass('small')) {
        $('#' + target).val('00');
      }
      else {
        if (currentTime < 9) {
          $('#' + target).val('0' + (currentTime + 1));
        }
        else {
          $('#' + target).val(currentTime + 1);
        }
      }
      if ($('#' + target).val() == 12) {
        var p = targetPeriodVal.removeClass('current').siblings('.timepicker__ampm').addClass('current').text();
        period = p;
      }
      if ($('#' + target).hasClass('start')) {
        if ($('#' + target).val() == 12 && ampmStart == 'am') {
          ampmStart = 'pm';
          $('#' + targetPeriod).val(ampmStart);
        } else if ($('#' + target).val() == 12 && ampmStart == 'pm') {
          ampmStart = 'am';
          $('#' + targetPeriod).val(ampmStart);
        }
      }
      if ($('#' + target).hasClass('end')) {
        if ($('#' + target).val() == 12 && ampmEnd == 'am') {
          ampmEnd = 'pm';
          $('#' + targetPeriod).val(ampmEnd);
        } else if ($('#' + target).val() == 12 && ampmEnd == 'pm') {
          ampmEnd = 'am';
          $('#' + targetPeriod).val(ampmEnd);
        }
      }
    }
    else if ($(this).hasClass('minus')) {
      if ($('#' + target).val() == min && $(this).closest('.timepicker').hasClass('big')) {
        $('#' + target).val(max);
      }
      else if ($('#' + target).val() == min && $(this).closest('.timepicker').hasClass('small')) {
        $('#' + target).val('00');
      }
      else if ($('#' + target).val() == 0 && $(this).closest('.timepicker').hasClass('small')) {
        $('#' + target).val('23');
      }
      else {
        if (currentTime < 11) {
          $('#' + target).val('0' + (currentTime - 1));
        }
        else {
          $('#' + target).val(currentTime - 1);
        }
      }
      if ($('#' + target).val() == 11) {
        var p = targetPeriodVal.removeClass('current').siblings('.timepicker__ampm').addClass('current').text();
        period = p;
      }
      if ($('#' + target).hasClass('start')) {
        if ($('#' + target).val() == 11 && ampmStart == 'am') {
          ampmStart = 'pm'
          $('#' + targetPeriod).val(ampmStart);
        } else if ($('#' + target).val() == 11 && ampmStart == 'pm') {
          ampmStart = 'am';
          $('#' + targetPeriod).val(ampmStart);
        }
      }
      if ($('#' + target).hasClass('end')) {
        if ($('#' + target).val() == 11 && ampmEnd == 'am') {
          ampmEnd = 'pm'
          $('#' + targetPeriod).val(ampmEnd);
        } else if ($('#' + target).val() == 11 && ampmEnd == 'pm') {
          ampmEnd = 'am';
          $('#' + targetPeriod).val(ampmEnd);
        }
      }
    }
    if (!($('#' + target).hasClass('end'))) {
      hoursStart = $('#' + target).val();
    }
    if (isPeriod && $('#' + target).hasClass('end')) {
      hoursEnd = $('#' + target).val();
    }
  }
  else if ($(this).hasClass('minutes')) {
    var min = 0;
    var max = 55;
    var targetHours = $(this).attr('data-hours');
    hours = parseInt($('#' + targetHours).val());
    if ($(this).hasClass('plus')) {
      if ($('#' + target).val() == max) {
        $('#' + target).val('00');
        //увеличение значения часов на единицу при клике на стрелку увеличения минут
        if (hours < 9) {
          $('#' + targetHours).val('0' + (hours + 1));
        }
        else if (hours == 12) {
          $('#' + targetHours).val('01');
        }
        else if (hours == 23) {
          $('#' + targetHours).val('00');
        }
        else {
          $('#' + targetHours).val(hours + 1);
        }
        if (hours == 11) {
          var p = targetPeriodVal.removeClass('current').siblings('.timepicker__ampm').addClass('current').text();
          period = p;
        }
        if ($('#' + target).hasClass('start')) {
          if (hours == 11 && ampmStart == 'am') {
            ampmStart = 'pm'
            $('#' + targetPeriod).val(ampmStart);
          } else if (hours == 11 && ampmStart == 'pm') {
            ampmStart = 'am'
            $('#' + targetPeriod).val(ampmStart);
          }
        }
        if ($('#' + target).hasClass('end')) {
          if (hours == 11 && ampmEnd == 'am') {
            ampmEnd = 'pm'
            $('#' + targetPeriod).val(ampmEnd);
          } else if (hours == 11 && ampmEnd == 'pm') {
            ampmEnd = 'am'
            $('#' + targetPeriod).val(ampmEnd);
          }
        }
      }
      else {
        if (currentTime < 5) {
          $('#' + target).val('0' + (currentTime + 5));
        }
        else {
          $('#' + target).val(currentTime + 5);
        }
      }
    }
    else if ($(this).hasClass('minus')) {
      if ($('#' + target).val() == min) {
        $('#' + target).val(max);
        //уменьшение значения часов на единицу при клике на стрелку уменьшения минут
        if (hours > 10) {
          $('#' + targetHours).val(hours - 1);
        }
        else if (hours == 1 && $(this).closest('.timepicker').hasClass('big')) {
          $('#' + targetHours).val('12');
        }
        else if (hours == 1 && $(this).closest('.timepicker').hasClass('small')) {
          console.log('YES');
          $('#' + targetHours).val('00');
        }
        else if (hours == 0 && $(this).closest('.timepicker').hasClass('small')) {
          $('#' + targetHours).val('23');
        }
        else {
          $('#' + targetHours).val('0' + (hours - 1));
        }
        if (hours == 12) {
          var p = targetPeriodVal.removeClass('current').siblings('.timepicker__ampm').addClass('current').text();
          period = p;
        }
        if ($('#' + target).hasClass('start')) {
          if (hours == 12 && ampmStart == 'am') {
            ampmStart = 'pm'
            $('#' + targetPeriod).val(ampmStart);
          } else if (hours == 12 && ampmStart == 'pm') {
            ampmStart = 'am'
            $('#' + targetPeriod).val(ampmStart);
          }
        }
        if ($('#' + target).hasClass('end')) {
          if (hours == 12 && ampmEnd == 'am') {
            ampmEnd = 'pm'
            $('#' + targetPeriod).val(ampmEnd);
          } else if (hours == 12 && ampmEnd == 'pm') {
            ampmEnd = 'am'
            $('#' + targetPeriod).val(ampmEnd);
          }
        }
      }
      else {
        if (currentTime < 15) {
          $('#' + target).val('0' + (currentTime - 5));
        }
        else {
          $('#' + target).val(currentTime - 5);
        }
      }
    }
    if (!($('#' + target).hasClass('end'))) {
      minutesStart = $('#' + target).val();
      hoursStart = $('#' + targetHours).val();
    }
    if (isPeriod && $('#' + target).hasClass('end')) {
      minutesEnd = $('#' + target).val();
      hoursEnd = $('#' + targetHours).val();
    }
  } else if ($(this).hasClass('ampm') && !($('#' + target).hasClass('end'))) {
    if ($('#' + target).val() == 'am') {
      $('#' + target).val('pm');
      ampmStart = 'pm';
      period = ampmStart;
    }
    else if ($('#' + target).val() == 'pm') {
      $('#' + target).val('am');
      ampmStart = 'am';
      period = ampmStart;
    }
  } else if ($(this).hasClass('ampm') && $('#' + target).hasClass('end')) {
    if ($('#' + target).val() == 'am') {
      $('#' + target).val('pm');
      ampmEnd = 'pm';
    }
    else if ($('#' + target).val() == 'pm') {
      $('#' + target).val('am');
      ampmEnd = 'am';
    }
  }
  period = period || ampmStart;
  if (!isPeriod) {
    finalTimeInput.val(hoursStart + ':' + minutesStart + ' ' + period);
  }
  if (isPeriod) {
    $(this).parents('.timepicker').find('.timepicker-period-apply').on('click touch', function () {
      finalTimeInput.val(hoursStart + ':' + minutesStart + ' ' + ampmStart + ' - ' + hoursEnd + ':' + minutesEnd + ' ' + ampmEnd);
    });
  }
});
$(document).on('click', '.timepicker__ampm', function () {
  var target = $(this).attr('data-time');
  var time = $('#' + target).val();
  var numbs = time.split(' ')[0];
  if ($(this).hasClass('am')) {
    $('#' + target).val(numbs + ' ' + 'AM');
  }
  else if ($(this).hasClass('pm')) {
    $('#' + target).val(numbs + ' ' + 'PM');
  }
});
